import React from 'react';
import '../../styles/pages/search-result.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../layout';

const SearchResult = () => {
  return (
    <Layout>
      <Container>
        <p style={{ fontSize: '24px', fontWeight: '600', color: '#333', borderBottom: '1px solid rgba(0,0,0,.15)' }}>
          Search results for: <span style={{ fontStyle: 'italic' }}>Audi A4 2008</span>
        </p>
        <p
          className="SearchResult--category"
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#666',
            marginBottom: '5px',
          }}
        >
          Air conditioning
        </p>
        <Row xs={2} sm={2} md={3} lg={4} style={{ maxWidth: '800px', paddingRight: '15px' }}>
          {[...Array(5)].map((i) => (
            <Col style={{ paddingRight: '0' }} key={i}>
              <div className="SearchResult--item">Item</div>
            </Col>
          ))}
        </Row>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#666',
            marginBottom: '5px',
          }}
        >
          Cooling system
        </p>
        <Row xs={2} sm={2} md={3} lg={4} style={{ maxWidth: '800px', paddingRight: '15px' }}>
          {[...Array(5)].map((i) => (
            <Col style={{ paddingRight: '0' }} key={i}>
              <div className="SearchResult--item">Item</div>
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

export default SearchResult;
